.kpi-overview-header {
  display: block;
  width: 100%;
  font-size: 20pt;
}

.kpi-blocks-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.kpi-block {
  flex: 1;
  min-width: 200px;
  background: #efefef;
  margin: 10px;
  padding: 10px;
}

.kpi-overview {
  text-align: center;
}

.kpi-block-header {
  font-size: 18pt;
}

.kpi-value {
  font-size: 50pt;
}

.kpi-comparison-container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.kpi-comparison {
  flex: 1;
  min-width: 140px;
}

.kpi-comparison-header {
  font-size: 14pt;
}

.kpi-comparison-ratio {
  font-size: 17pt;
  margin: 6px 0px;
}

.kpi-comparison-value {
  font-size: 25pt;
}
