/* DataTables fixes/overrides */

/* Use max. width */
table.dataTable {
  min-width: 100%;
}

/* Use pointer cursor for clickable rows */
.dataTable.clickable-rows tbody tr {
	cursor: pointer;
}

/* Correctly align left and right sections at the same row */
.dt-buttons {
  float: left;
  clear: both;
  margin: 0 3px;
}

.dataTables_length {
  float: left;
}

.dataTables_filter {
  float: right;
}

.dataTables_info {
  float: left;
  clear: both;
}

.dataTables_paginate {
  float: right;
}

/* Ensure that the highlight of the input boxes is not cut off */
div.dataTables_wrapper div { /* Very specific selector to prevent overwriting by Bootstrap */
  &.dataTables_length, &.dataTables_filter, &.dataTables_info, &.dataTables_paginate {
    margin: 5px 3px;
  }
}

/* Improve sorting icons */
table.dataTable thead {
  .sorting, .sorting_asc, .sorting_desc, .sorting_asc_disabled, .sorting_desc_disabled {
    background: transparent;
    padding-right: 15px;

    &:before, &:after {
      display: none;
      content: none;
    }
  }

  .sorting_asc:before {
    display: block;
    content: "\f0de";
    right: 0.5em;
    font-family: fontawesome;
  }

  .sorting_desc:after {
    display: block;
    content: "\f0dd";
    right: 0.5em;
    font-family: fontawesome;
  }

  .sorting:after {
    display: block;
    content: "\f0dc";
    right: 0.5em;
    font-family: fontawesome;
  }
}

/* Loading overlay */
.dataTables_wrapper.dt-loading .dataTable:before {
  content: '';
  display: block;
  background: rgba(255, 255, 255, 0.8);
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
}

.dataTables_wrapper .dataTable {
  position: relative;
  margin: 0 -6px; /* Fix for horizontal scrollbar */
}

.dataTables_wrapper.dt-loading .dataTable:after {
  content: '\F110';
  animation: fa-spin 1s infinite steps(8);
  font: normal normal normal 14px/1 FontAwesome;
  font-size: 48px;
  display: inline-block;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -24px;
  margin-top: -24px;
  color: #404040;
  z-index: 1000;
  clear: both;
}

// Workaround for dropdowns being cut off if there is not enough content in the table
.dataTables_wrapper {
  min-height: 350px;
}

// Prevent columns getting too narrow on mobile
@media (max-width: 767px) {
  table.dataTable td, table.dataTable th {
    min-width: 100px;
  }
}
